import React from 'react'
import fetch from 'isomorphic-unfetch'
import styled from 'styled-components'
import NProgress from 'nprogress'
import Router from 'next/router'
import { setToken, setTempToken, getTempToken, unsetTempToken } from '../utils/auth'
import config from '../config'
import { updateUser } from '../store'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import bowser from 'bowser'
import { withApollo } from "react-apollo"
import { UserQuery } from "../hocs/securePage"
import Formsy from 'formsy-react';
import MyInput from '../components/MyInput';
import jwtDecode from 'jwt-decode'
import Cookie from 'js-cookie'
import { Subscription, Query } from "react-apollo"
import gql from "graphql-tag"
import moment from 'moment'
import { Userfragments } from "../hocs/securePage"


export const Titulo = styled.div`
font-weight:bold;
  font-size: 18px;
  margin-bottom: 25px;
  color: #5f5f5f;
`
const Input = styled(MyInput)`
flex: 1;
margin: 0 5px;
  & input{
    font-size: 16px;
    line-height: normal;
    
    border-radius: 3px;
    box-sizing:border-box;
    padding: 6px 10px;
    color: #000;
    background-color: #fff;
    border-width: 1px;
    border-color: #d4d4d4;
    border-style: solid;
    font-family: inherit;
    outline-width:initial;
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${props => props.last ? '0' : '10px'};
`
const BotonRegistro = styled(Input.withComponent('button'))`
margin: 0 5px;
    font-size: 16px;
    line-height: normal;
    border-radius: 3px;
    padding: 6px 10px;
    color: #000;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    font-family: inherit;
    outline-width:initial;
  margin-left: auto;
  flex:0;
  border-color:#000;
  margin-top:15px;
  width: initial;
  outline-width:initial;
`

const Root = styled.div`
  font-family: "Open Sans", Roboto, Helvetica;
  font-size: 15px;
`

const AhorraContainer = styled.div`
position: absolute;
right: -6px;
top: -13px;
background-color: #27a917;
color: #fff;
text-align: center;
height: 50px;
width: 50px;
border-radius: 50px;
display: flex;
flex-direction: column;
justify-content: center;
`

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.registrarme = this.registrarme.bind(this)
    const tempToken = this.props.pago && (props.tempToken || getTempToken())
    this.state = {
      ...tempToken,
      plan: 1
    }

    console.log("constructing signup", tempToken)
  }

  registrarme = async (model) => {
    NProgress.start()
    bowser.detect(navigator.userAgent)
    try {
      const { data: { signUp: { user, token } } } = await this.props.client.mutate({
        mutation: signUpMutation,
        variables: {
          input: {
            first_name: model.first_name,
            last_name: model.last_name,
            email: model.email,
            password: model.password,
            code: this.props.cupon,
            os: {
              browser: bowser.name,
              name: bowser.osname,
              version: bowser.osversion
            }
          }
        }
      })
      console.log(user)

      if (user.signup_status == 0) {
        this.login(token)
      } else {
        const tempToken = {
          businessId: user.businesses[0].id,
          userId: user.id,
          token: token
        }
        setTempToken(tempToken)
        this.setState(tempToken)
        fbq('track', 'StartTrial');
      }
    } catch (error) {
      if (error.graphQLErrors[0].code == "DUPLICATED")
        alert("Este usuario ya existe")
      else {
        console.log("Ocurrio un error")
      }
    }

    NProgress.done()
  }

  goToPaypal = () => {
    this.formPaypal.submit()
  }

  login = (token) => {
    unsetTempToken()
    setToken(token || this.state.token)
    this.props.client.query({
      fetchPolicy: 'network-only',
      query: UserQuery
    })
    const newState = { ...history.state, time: Date.now() }
    history.pushState(newState, null, location.href)
    Router.replace('/')
  }

  render() {
    let payment
    const pagoQuery = this.props.pago
    return <Root>
      {!this.state.token && <Formsy onValidSubmit={this.registrarme}>
        <div style={{ marginBottom: 3 }}>1. Crea tu cuenta</div>
        <Step>
          <StepDesc>Paso 1 de 2</StepDesc>
          <Bar>
            <Progress style={{ width: "50%" }} ></Progress>
          </Bar>
        </Step>
        <Row>
          <Input required name="first_name" type="text" placeholder="Nombre" />
          <Input required name="last_name" type="text" placeholder="Apellidos" />
        </Row>
        <Row><Input validations="isEmail" validationError="Email invalido" required name="email" type="email" placeholder="Correo" /></Row>
        <Row><Input validations={{
          minLength: 8,
          matchRegexp: /^\S*$/
        }} validationErrors={{
          minLength: "Contraseña debe de ser al menos de 8 caracteres",
          matchRegexp: "No puede contener espacios"
        }} required name="password" type="password" placeholder="Contraseña" /></Row>
        <Row last><BotonRegistro2 style={{ flex: 1, marginTop: 30 }}>Siguiente</BotonRegistro2></Row>
      </Formsy> || <div>
          <div style={{ marginBottom: 3 }}>2. Elige tu plan posterior a la prueba</div>
          <Step>
            <StepDesc>Paso 2 de 2</StepDesc>
            <Bar>
              <Progress></Progress>
            </Bar>
          </Step>
          {!pagoQuery && <div>
            <Plan disabled={pagoQuery} onClick={() => { if (!pagoQuery) this.setState({ plan: 0 }) }} selected={this.state.plan == 0} style={{ marginBottom: 10 }}>
              <Mark>{this.state.plan == 0 && <Dot></Dot>}</Mark>
              <div>
                <div><Bold>Anual</Bold> - $166.58 MXN / mensual</div>
                <Sub>$1,999.00 MXN cobrado anualmente</Sub>
              </div>
              <AhorraContainer>
                <div style={{ fontSize: "11px" }}>Ahorra</div>
                <div style={{ fontSize: "19px", fontWeight: "bold" }}>19%</div>
              </AhorraContainer>
            </Plan>
            <Plan disabled={pagoQuery} onClick={() => { if (!pagoQuery) this.setState({ plan: 1 }) }} selected={this.state.plan == 1}>
              <Mark>{this.state.plan == 1 && <Dot></Dot>}</Mark>
              <div>
                <div><Bold>Mensual</Bold> - $199.00 MXN / mensual</div>
                <Sub>$199.00 MXN cobrado mensualmente</Sub>
              </div>
            </Plan>
            <Mensaje>Tu prueba de 30 días es <span style={{ fontWeight: "bold" }}>100% gratis</span>. <div>Cancela en cualquier momento.</div></Mensaje>
            {false && <React.Fragment><Plan>
              Tarjeta de credito o debito
            </Plan>
              <Plan>
                Paypal
            </Plan></React.Fragment>}
          </div>}
          <Query fetchPolicy={"cache-and-network"} query={suscriptionQuery} variables={{ businesses_id: this.state.businessId }}>
            {({ loading, error, data: { suscription }, refetch }) => {
              payment = suscription && moment.utc(suscription.ending, 'YYYY-MM-DD HH:mm:ss').local()
              return (<Subscription subscription={paymentSubscription}
                variables={{ businesses_id: this.state.businessId }}
              >
                {({ data, loading }) => {
                  if (!payment)
                    payment = data && moment.utc(data.newPayment.ending, 'YYYY-MM-DD HH:mm:ss').local()
                  return <div><div style={{ margin: "20px 0 0" }}>
                    {!pagoQuery && !payment && <BotonSuscribir onClick={this.goToPaypal}><img style={{ width: 20, marginRight: 10 }} src={config.api + "/static/payPal.png"} alt="" />Suscribirse</BotonSuscribir> || !payment && <div style={{ textAlign: "center" }}>
                      <i style={{ color: "#0053d0" }} className="fa fa-spinner fa-spin fa-2x fa-fw"></i>
                      <div style={{ marginTop: 10, marginBottom: 20 }}>Tu suscripción esta siendo procesada</div>
                    </div> || payment && <div style={{ textAlign: "center" }}>
                      <div>Tu suscripción se renovara automaticamente el <span style={{ color: "#6f6f6f", marginTop: 7 }} >{payment.format("dddd D MMMM, YYYY")}</span>
                      </div>
                    </div>}
                  </div>
                    {pagoQuery && <BotonRegistro2 onClick={() => { if (payment) this.login() }} inactive={!payment}>Comenzar a usar Aggendi</BotonRegistro2>}
                  </div>
                }}
              </Subscription>)
            }}
          </Query>
        </div>}
      <form ref={ref => this.formPaypal = ref} style={{ display: "none" }} action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
        <input type="hidden" name="custom" value={"user=" + this.state.userId + "&business=" + this.state.businessId} />
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="CRVUVCMBVPQZY" />
        <input type="hidden" name="charset" value="utf-8" />
        <table style={{ margin: "10px auto" }}>
          <tbody>
            <tr><td><input type="hidden" name="on0" value="Plan" /></td></tr>
            <tr><td>
              <select readOnly={true} value={this.state.plan == 0 ? "Anual" : "Mensual"} style={{ backgroundColor: "#fff", display: "none" }} name="os0">
                <option value="Mensual">$199.00 MXN al mes, despues de la prueba</option>
                <option value="Anual">$1,999.00 MXN al año, despues de la prueba</option>
              </select>
              <div>$199.00 MXN al mes, despues de la prueba</div>
            </td></tr>
          </tbody>
        </table>
        <input type="hidden" name="currency_code" value="MXN" />

        <div style={{ fontSize: 14, fontWeight: 400 }}>Cancela en cualquier momento</div>
        <input style={{ marginTop: 20 }} type="image" src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea." />
        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1" />
      </form>
    </Root>
  }
}

const suscriptionQuery = gql`
query suscription($businesses_id: Int) {
    suscription(businesses_id: $businesses_id){
        id
        starting
        ending
    }
}
`

const paymentSubscription = gql`
subscription onNewPayment($businesses_id: Int!) {
  newPayment(businesses_id: $businesses_id) {
    id
    ending
  }
}
`

const signUpMutation = gql`
mutation signUp($input: SignUpInput){
  signUp(input: $input){
    user{
        ...userFragment
      }
      token
  }
}
${Userfragments.user}
`

const Step = styled.div`
display:flex;
flex-direction: row;
align-items: center;
margin-bottom:20px;
`

const StepDesc = styled.div`
color: #888;
font-size: 13px;
margin-right: 10px;
`

const Bar = styled.div`
background-color: #d0d0d0;
width: 70px;
height: 9px;
border-radius: 10px;
margin: 10px 0;
overflow: hidden;
`

const Progress = styled.div`
width:100%;
height:100%;
background-color:#06a917;
box-shadow: inset 0px -1px 2px -1px #3c3c3c;
`

const Mensaje = styled.div`
font-size: 15px;
font-weight: 400;
text-align: center;
color: #4e4e4e;
font-family: Helvetica;
margin-top: 25px;
padding-bottom: 5px;
`

const BotonRegistro2 = styled(BotonRegistro)`
width: 100%;
background-color: ${props => props.inactive ? "rgba(6, 169, 23, 0.43)" : "rgba(6, 169, 23, 1)"};
border: none;
color: #fff;
font-weight: bold;
margin-top:0;
border-radius:20px;
`

const BotonSuscribir = styled.div`
cursor:pointer;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
background-color: #0057dc;
color: #fff;
padding: 10px;
border-radius: 20px;
`

const Dot = styled.div`
width: 8px;
    height: 8px;
    background-color: #0053d0;
    border-radius: 8px;
`

const Mark = styled.div`
width: 14px;
height: 14px;
border-radius: 14px;
border: 1px solid #0053d0;
margin-right: 13px;
margin-top: 2px;
box-shadow: 1px 1px 4px -2px #2d2d2d;
display:flex;
justify-content:center;
align-items:center;
`

const Plan = styled.div`
position:relative;
${props => props.disabled ? null : "cursor:pointer;"}
${props => props.disabled ? "opacity: .5;" : null}
border: 1px solid rgb(212, 212, 212);
padding: 15px 12px;
border-radius: 3px;
display:flex;
flex-direction:row;
${props => props.selected ? "background-color:#f0f6ff" : null}
`

const Bold = styled.span`
  font-weight:bold;
`

const Sub = styled.div`
color: #828282;
margin-top: 7px;
font-size: 13px;
`

export default withApollo(Signup)